import React, { Component } from "react";
import TokenItem from "./TokenItem";

import "./style.css";
const arrToken = [
  {
    "Name": "BTCF",
    "Img": "images/token/0xE0e514c71282b6f4e823703a39374Cf58dc3eA4f.png",
    "Des": "belt"
  },
  {
    "Name": "BTCNFT",
    "Img": "images/token/0xE0e514c71282b6f4e823703a39374Cf58dc3eA4f.png",
    "Des": "belt"
  },
  {
    "Name": "BTCFX",
    "Img": "images/token/0xE0e514c71282b6f4e823703a39374Cf58dc3eA4f.png",
    "Des": "belt"
  },
  {
    "Name": "BTCGE",
    "Img": "images/token/0xE0e514c71282b6f4e823703a39374Cf58dc3eA4f.png",
    "Des": "belt"
  },
  {
    "Name": "BTCEX",
    "Img": "images/token/0xE0e514c71282b6f4e823703a39374Cf58dc3eA4f.png",
    "Des": "belt"
  }
]
class SelectToken extends Component {


  constructor(props) {
    super(props);


  }


  render() {
    let { SelectTokenf, selectTokens } = this.props;
    // NotificationText = "Successfully"
    return (
      <div className="select_token">
        <div className="content" style={{ background: '#222222' }}>
          <div className="title">
            <h4>Select a Token</h4>
            <button className="" onClick={() => { SelectTokenf(false) }}><img width={25} src="https://cdn-icons-png.flaticon.com/512/32/32178.png" alt="" /></button>
          </div>
          <div className="tokens" style={{ height: 'auto' }}>
            {arrToken && arrToken.map((item, index) => {
              return <TokenItem selectTokens={selectTokens} name={item.Name} img={item.Img} des={item.Des} />
            })
            }
          </div>
        </div>
      </div>
    );
  }
}



export default SelectToken;
