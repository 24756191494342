import React, { Component } from "react";


import "./style.css";
class Swaptab1 extends Component {


  constructor(props) {
    super(props);
    this.state = {
      input1: "",
      input2: "",

    }
  }

  onKeyUpVal = (type, e) => {
    let value = e.target.value;
    let val2 = '';
    let val1 = '';
    if (type === 1) {
      val1 = value;
      val2 = value * this.props.rateAllToken[this.props.isTokenSelect];
    }
    if (type === 2) {
      val2 = value;
      val1 = value / this.props.rateAllToken[this.props.isTokenSelect];
    }
    this.props.handleChange(val1)
    this.setState(state => {
      return {
        input1: val1,
        input2: val2,
      }
    })
  }
  render() {
    let { SelectTokenf, Setingshow, Approve, submitAprove, Loading, submitConnect, _submitApprove, _SubmitConnect, isTokenSelect, exchange, changeExchange, approveAllToken } = this.props;
    let disableAprove = false;
    disableAprove = submitAprove ? true : false;
    let disableConnect = false;
    disableConnect = submitConnect ? true : false;

    // NotificationText = "Successfully"
    return (
      <div id="cus_id" className="sc-iemWCZ hvoWDu sc-eSoXWK WzEhh" style={{ background: "unset", padding: "0px" }}>
        <div className="sc-jSFjdj sc-dIvrsQ kJmatq hdzOgI">
          <div className="sc-jSFjdj sc-gKAaRy sc-jNjAJB kJmatq jcNvwq jvbdxC swap-page__header">
            <div className="sc-jSFjdj sc-gKAaRy cpromH togOu"><a className="sc-eCApnc fAYopv">
              {/* <div
                style={{ background: "rgb(255, 255, 255)", borderRadius: "50%", height: "40px", width: "40px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <img src="/images/image-ether.png" width="30" alt="" />
              </div> */}
            </a>
              <div className="sc-jSFjdj sc-gKAaRy kJmatq iIdAKa">
                <h2 color="text" className="sc-gtsrHT sc-iJCRrE iGtzpP jXZUNo"
                  style={{ color: "rgb(255, 255, 255)", fontWeight: "500", fontSize: "28px", fontFamily: "sans-serif", margin: "0px" }}>
                  Best Swap Rates</h2>
                <div className="sc-jSFjdj sc-gKAaRy kJmatq togOu"
                  style={{ color: "rgb(255, 255, 255)", fontWeight: "500", fontSize: "16px", fontFamily: "sans-serif" }}>
                  <div color="#fff" fontSize="14px" className="sc-gtsrHT iNjjsC">Trade tokens in an instant</div>
                </div>
              </div>
            </div>
            <div className="sc-jSFjdj sc-gKAaRy kJmatq togOu"><span className="sc-cTJkRt lmGFyU">
              <div className="sc-jSFjdj sc-gKAaRy kJmatq jcNvwq"><button className="sc-hKFxyN iLUutT sc-eCApnc fAYovO"
                scale="sm" id="open-settings-dialog-button" style={{ padding: "0" }} onClick={() => { Setingshow(true) }}>
                <img src="images/settings.png" alt="" />

              </button></div><span className="sc-jNnpgg cFAkok"></span>
            </span></div>
          </div>
          <div className="sc-hLyimJ gvfiVX">
            <div className="sc-iuhXDa jRhYjA">
              <div className="sc-jSFjdj sc-gKAaRy kJmatq ViahK">
                <div fontSize="14px" color="text" className="sc-gtsrHT jFEWVt" style={{ color: "rgb(255, 255, 255)" }}>
                  From</div>
              </div>
              {
                exchange ?
                  <div id="swap-currency-input" className="sc-kkmGkm dmroTs">
                    <div className="sc-bXmHAB kBJBJb">
                      <div className="sc-gLMgcV cuDSlQ"
                        style={{ padding: "5px 15px", background: "rgb(15, 7, 44)", border: "1px solid rgba(136, 138, 141, 0.37)", borderRadius: "5px" }}>
                        <input className="sc-iKUVsf dYnuxz token-amount-input" inputMode="decimal" title="Token Amount"
                          autoComplete="off" autoCorrect="off" type="text" pattern="^[0-9]*[.,]?[0-9]*$"
                          placeholder="0.0" minLength="1" maxLength="79" spellCheck="false"
                          style={{ color: "rgb(255, 255, 255)" }} value={this.state.input1} onChange={(event) => { this.onKeyUpVal(1, event) }} /><button
                            className="sc-hKFxyN jYLfuR sc-hHSjgo gEfhZZ open-currency-select-button" scale="sm"
                            style={{ color: "rgb(255, 255, 255)" }} >
                          <div className="sc-jSFjdj sc-gKAaRy kJmatq eqGhLO">
                            <div id="pair" color="text" className="sc-gtsrHT MlLjM" style={{ color: "rgb(255, 255, 255)" }}>USDT
                            </div>
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                  :
                  <div id="swap-currency-output" className="sc-kkmGkm dmroTs">
                    <div className="sc-bXmHAB kBJBJb">
                      <div className="sc-gLMgcV cuDSlQ"
                        style={{ padding: "5px 15px", background: "rgb(15, 7, 44)", border: "1px solid rgba(136, 138, 141, 0.37)", borderRadius: "5px" }}>
                        <input className="sc-iKUVsf dYnuxz token-amount-input" inputMode="decimal" title="Token Amount"
                          autoComplete="off" autoCorrect="off" type="text" pattern="^[0-9]*[.,]?[0-9]*$"
                          placeholder="0.0" minLength="1" maxLength="79" spellCheck="false" value={this.state.input2} onChange={(event) => { this.onKeyUpVal(2, event) }}
                          style={{ color: "rgb(255, 255, 255)" }} /><button
                            className="sc-hKFxyN jYLfuR sc-hHSjgo gEfhZZ open-currency-select-button" scale="sm"
                            style={{ color: "rgb(255, 255, 255)" }} onClick={() => {
                              SelectTokenf(true);
                            }}>
                          <div className="sc-jSFjdj sc-gKAaRy kJmatq eqGhLO">
                            <div id="pair" color="text" className="sc-gtsrHT MlLjM" style={{ color: "rgb(255, 255, 255)" }}>{isTokenSelect}
                            </div>
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
              }

              <div className="sc-iuhXDa lfXJSM">
                <div className="sc-jSFjdj sc-jVSGNQ sc-jfkLlK kJmatq ktSqPa kfEQsw" style={{ padding: "0px 1rem" }}><button
                  onClick={changeExchange}
                  className="sc-hKFxyN eiRTmt sc-eCApnc fAYopv" scale="md"
                  style={{ background: "unset", boxShadow: "unset", padding: 0 }}><img
                    src="https://png.pngtree.com/png-vector/20190215/ourlarge/pngtree-vector-direction-icon-png-image_515531.jpg" width="24"
                    alt="" /></button></div>
              </div>
              <div className="sc-jSFjdj sc-gKAaRy kJmatq ViahK">
                <div fontSize="14px" color="text" className="sc-gtsrHT jFEWVt" style={{ color: "rgb(255, 255, 255)" }}>To
                  (estimated)</div>
              </div>
              {
                !exchange ?
                  <div id="swap-currency-input" className="sc-kkmGkm dmroTs">
                    <div className="sc-bXmHAB kBJBJb">
                      <div className="sc-gLMgcV cuDSlQ"
                        style={{ padding: "5px 15px", background: "rgb(15, 7, 44)", border: "1px solid rgba(136, 138, 141, 0.37)", borderRadius: "5px" }}>
                        <input className="sc-iKUVsf dYnuxz token-amount-input" inputMode="decimal" title="Token Amount"
                          autoComplete="off" autoCorrect="off" type="text" pattern="^[0-9]*[.,]?[0-9]*$"
                          placeholder="0.0" minLength="1" maxLength="79" spellCheck="false"
                          style={{ color: "rgb(255, 255, 255)" }} value={this.state.input1} onChange={(event) => { this.onKeyUpVal(1, event) }} /><button
                            className="sc-hKFxyN jYLfuR sc-hHSjgo gEfhZZ open-currency-select-button" scale="sm"
                            style={{ color: "rgb(255, 255, 255)" }} >
                          <div className="sc-jSFjdj sc-gKAaRy kJmatq eqGhLO">
                            <div id="pair" color="text" className="sc-gtsrHT MlLjM" style={{ color: "rgb(255, 255, 255)" }}>USDT
                            </div>
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                  :
                  <div id="swap-currency-output" className="sc-kkmGkm dmroTs">
                    <div className="sc-bXmHAB kBJBJb">
                      <div className="sc-gLMgcV cuDSlQ"
                        style={{ padding: "5px 15px", background: "rgb(15, 7, 44)", border: "1px solid rgba(136, 138, 141, 0.37)", borderRadius: "5px" }}>
                        <input className="sc-iKUVsf dYnuxz token-amount-input" inputMode="decimal" title="Token Amount"
                          autoComplete="off" autoCorrect="off" type="text" pattern="^[0-9]*[.,]?[0-9]*$"
                          placeholder="0.0" minLength="1" maxLength="79" spellCheck="false" value={this.state.input2} onChange={(event) => { this.onKeyUpVal(2, event) }}
                          style={{ color: "rgb(255, 255, 255)" }} /><button
                            className="sc-hKFxyN jYLfuR sc-hHSjgo gEfhZZ open-currency-select-button" scale="sm"
                            style={{ color: "rgb(255, 255, 255)" }} onClick={() => {
                              SelectTokenf(true);
                            }}>
                          <div className="sc-jSFjdj sc-gKAaRy kJmatq eqGhLO">
                            <div id="pair" color="text" className="sc-gtsrHT MlLjM" style={{ color: "rgb(255, 255, 255)" }}>{isTokenSelect}
                            </div>
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
              }
              {/* <div id="swap-currency-output" className="sc-kkmGkm dmroTs">
                <div className="sc-bXmHAB kBJBJb">
                  <div className="sc-gLMgcV cuDSlQ"
                    style={{ padding: "5px 15px", background: "rgb(15, 7, 44)", border: "1px solid rgba(136, 138, 141, 0.37)", borderRadius: "5px" }}>
                    <input className="sc-iKUVsf dYnuxz token-amount-input" inputMode="decimal" title="Token Amount"
                      autoComplete="off" autoCorrect="off" type="text" pattern="^[0-9]*[.,]?[0-9]*$"
                      placeholder="0.0" minLength="1" maxLength="79" spellCheck="false" value={this.state.input2} onChange={(event) => { this.onKeyUpVal(2, event) }}
                      style={{ color: "rgb(255, 255, 255)" }} /><button
                        className="sc-hKFxyN jYLfuR sc-hHSjgo gEfhZZ open-currency-select-button" scale="sm"
                        style={{ color: "rgb(255, 255, 255)" }} onClick={() => {
                          SelectTokenf(true);
                        }}>
                      <div className="sc-jSFjdj sc-gKAaRy kJmatq eqGhLO">
                        <div id="pair" color="text" className="sc-gtsrHT MlLjM" style={{ color: "rgb(255, 255, 255)" }}>{isTokenSelect}
                        </div>
                      </div>
                    </button>
                  </div>
                </div>
              </div> */}

            </div>


            <div className="sc-jSFjdj jRqThc">
              {(exchange ? approveAllToken['USDT'] : approveAllToken[isTokenSelect]) &&
                <button className="sc-hKFxyN hizvcO" id="join-pool-button" width="100%" variant="primary" scale="md"
                  disabled={disableConnect} onClick={_SubmitConnect}>
                  Swap {submitConnect && <Loading />}
                </button>
              }
              {!(exchange ? approveAllToken['USDT'] : approveAllToken[isTokenSelect]) &&
                <button className="sc-hKFxyN hizvcO" id="join-pool-button" width="100%" variant="primary" scale="md"
                  disabled={disableAprove} onClick={_submitApprove}>
                  Approve  {submitAprove && <Loading />}
                </button>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}



export default Swaptab1;
