import React, { useEffect, useRef, useState } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { router } from "routes";
import Layout from "components/Layout";
import { useDispatch, useSelector } from "react-redux";
import { get_address } from "store/address_web3/action";
import { get_current_loop } from "store/current_loop/action";
import { get_price } from "store/price/action";
import { get_percent_farm } from "store/percent_farm/action";
import { get_user } from "store/user/action";
import Login from "views/login";

const AppRoute = ({ component: Component, exact, title, ...rest }) => {
  return (
    <Route
      exact={exact}
      {...rest}
      render={(props) => (
        <Layout title={title}>
          <Component {...props} />
        </Layout>
      )}
    />
  );
};

const Pages = () => {
  const state = useSelector((state) => state);
  const savedCallback = useRef();
  const dispatch = useDispatch();
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.enable().then(() => { });
    }
    dispatch(get_address());
    dispatch(get_current_loop());
    dispatch(get_price());
    dispatch(get_percent_farm());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state.address_web3) {
      dispatch(get_user(state.address_web3.toLowerCase()));
      if (state.address_web3 !== savedCallback.current) {
        clearInterval(savedCallback.current);
      }
      savedCallback.current = state.address_web3;
      savedCallback.current = setInterval(() => {
        dispatch(get_user(state.address_web3.toLowerCase()));
        // console.clear();
      }, 5000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.address_web3]);

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (windowSize.width > 992) {
      dispatch({
        type: "ACTIVE",
        payload: true,
      });
    } else {
      dispatch({
        type: "ACTIVE",
        payload: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowSize.width]);

  return (
    <BrowserRouter>
      <Switch>
        {router.map((e, i) =>
          e.children ? (
            e.children.map((child, index) => (
              <AppRoute
                key={index}
                path={child.path}
                component={child.component}
                exact={child.exact}
                title={child.title}
              />
            ))
          ) : (
            <AppRoute
              key={i}
              path={e.path}
              component={e.component}
              exact={e.exact}
              title={e.title}
            />
          )
        )}
        <Route path={"/btcf/login"} component={Login} />
        <Redirect from="*" to="/btcf/home" />
      </Switch>
    </BrowserRouter>
  );
};
export default Pages;
