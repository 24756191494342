import React, { Component } from "react";


import "./style.css";
class AddLiquidity extends Component
{


  constructor(props)
  {
    super(props);


  }


  render()
  {
    let { Setingshow, ChangeTab, SelectTokenf } = this.props;
    // NotificationText = "Successfully"
    return (
      <div id="cus_id" className="sc-iemWCZ hvoWDu sc-eSoXWK WzEhh" style={{ background: "unset", padding: "0px" }}>
        <div className="sc-jSFjdj sc-dIvrsQ kJmatq hdzOgI">
          <div className="sc-jSFjdj sc-gKAaRy sc-jNjAJB kJmatq jcNvwq jvbdxC swap-page__header">
            <button onClick={() => { ChangeTab(true) }}>
              <img src="images/goback.png" alt="" />
            </button>
            <div className="sc-jSFjdj sc-gKAaRy cpromH togOu"><a className="sc-eCApnc fAYopv" href="">

              <div
                style={{ background: "rgb(255, 255, 255)", borderRadius: "50%", height: "40px", width: "40px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <img src="/images/image-ether.png" width="30" alt="" /></div>
            </a>
              <div className="sc-jSFjdj sc-gKAaRy kJmatq iIdAKa">

                <h2 color="text" className="sc-gtsrHT sc-iJCRrE iGtzpP jXZUNo"
                  style={{ color: "rgb(255, 255, 255)", fontWeight: "500", fontSize: "28px", fontFamily: "sans-serif", margin: "0px" }}>
                  ADD Liquidity</h2>
                <div className="sc-jSFjdj sc-gKAaRy kJmatq togOu"
                  style={{ color: "rgb(255, 255, 255)", fontWeight: "500", fontSize: "16px", fontFamily: "sans-serif" }}>
                  <div color="#fff" fontSize="14px" className="sc-gtsrHT iNjjsC">
                    <span title="Adjusts the gas price (transaction fee) for your transaction. Higher GWEI = higher speed = higher fees" className="tooltips">
                      <i class="fa fa-question-circle-o" aria-hidden="true"></i></span>Add liquidity to receive LP tokens</div>
                </div>
              </div>
            </div>
            <div className="sc-jSFjdj sc-gKAaRy kJmatq togOu"><span className="sc-cTJkRt lmGFyU">
              <div className="sc-jSFjdj sc-gKAaRy kJmatq jcNvwq"><button className="sc-hKFxyN iLUutT sc-eCApnc fAYovO"
                scale="sm" id="open-settings-dialog-button" onClick={() => { Setingshow(true) }}>  <img src="images/settings.png" alt="" /></button></div><span className="sc-jNnpgg cFAkok"></span>
            </span></div>
          </div>
          <div className="sc-hHEiqL sc-jxFFCz fmYIqW hTpcqx"
            style={{}}>
            <div color="white" className="sc-gtsrHT bwlMme">Connect to a wallet to view your liquidity.</div>
          </div>
          <div className="sc-hLyimJ gvfiVX">
            <div className="sc-iuhXDa jRhYjA">
              <div className="sc-jSFjdj sc-gKAaRy kJmatq ViahK">
                <div fontSize="14px" color="text" className="sc-gtsrHT jFEWVt" style={{ color: "rgb(255, 255, 255)" }}>
                  From</div>
              </div>
              <div id="swap-currency-input" className="sc-kkmGkm dmroTs">
                <div className="sc-bXmHAB kBJBJb">
                  <div className="sc-gLMgcV cuDSlQ"
                    style={{ padding: "5px 15px", background: "rgb(15, 7, 44)", border: "1px solid rgba(136, 138, 141, 0.37)", borderRadius: "5px" }}>
                    <input className="sc-iKUVsf dYnuxz token-amount-input" inputMode="decimal" title="Token Amount"
                      autoComplete="off" autoCorrect="off" type="text" pattern="^[0-9]*[.,]?[0-9]*$"
                      placeholder="0.0" minLength="1" maxLength="79" spellCheck="false" value="123"
                      style={{ color: "rgb(255, 255, 255)" }} /><button
                        className="sc-hKFxyN jYLfuR sc-hHSjgo gEfhZZ open-currency-select-button" scale="sm"
                        style={{ color: "rgb(255, 255, 255)" }} onClick={() =>
                        {
                          SelectTokenf(true);
                        }}>
                      <div className="sc-jSFjdj sc-gKAaRy kJmatq eqGhLO"><svg viewBox="0 0 16 16" width="24px"
                        color="text" xmlns="http://www.w3.org/2000/svg" className="sc-bdnxRM kDWlca"
                        style={{ marginRight: "8px" }}>
                        <circle cx="8" cy="8" r="8" fill="#F0B90B"></circle>
                        <path
                          d="M5.01656 8.00006L3.79256 9.23256L2.56006 8.00006L3.79256 6.76756L5.01656 8.00006ZM8.00006 5.01656L10.1081 7.12456L11.3406 5.89206L9.23256 3.79256L8.00006 2.56006L6.76756 3.79256L4.66806 5.89206L5.90056 7.12456L8.00006 5.01656ZM12.2076 6.76756L10.9836 8.00006L12.2161 9.23256L13.4401 8.00006L12.2076 6.76756ZM8.00006 10.9836L5.89206 8.87556L4.66806 10.1081L6.77606 12.2161L8.00006 13.4401L9.23256 12.2076L11.3406 10.0996L10.1081 8.87556L8.00006 10.9836ZM8.00006 9.23256L9.23256 8.00006L8.00006 6.76756L6.76756 8.00006L8.00006 9.23256Z"
                          fill="#FFFDFA"></path>
                      </svg>
                        <div id="pair" color="text" className="sc-gtsrHT MlLjM" style={{ color: "rgb(255, 255, 255)" }}>BNB
                        </div>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
              <div className="sc-iuhXDa lfXJSM">
                <div className="sc-jSFjdj sc-jVSGNQ sc-jfkLlK kJmatq ktSqPa kfEQsw" style={{ padding: "0px 1rem" }}><button
                  className="sc-hKFxyN eiRTmt sc-eCApnc fAYopv" scale="md"
                  style={{ background: "unset", boxShadow: "unset" }}><img
                    src="https://dapp.sftdefi.info/images/icon-swap-vertical.22350bd6.svg" width="24"
                    alt="" /></button></div>
              </div>
              <div className="sc-jSFjdj sc-gKAaRy kJmatq ViahK">
                <div fontSize="14px" color="text" className="sc-gtsrHT jFEWVt" style={{ color: "rgb(255, 255, 255)" }}>To
                  (estimated)</div>
              </div>
              <div id="swap-currency-output" className="sc-kkmGkm dmroTs">
                <div className="sc-bXmHAB kBJBJb">
                  <div className="sc-gLMgcV cuDSlQ"
                    style={{ padding: "5px 15px", background: "rgb(15, 7, 44)", border: "1px solid rgba(136, 138, 141, 0.37)", borderRadius: "5px" }}>
                    <input className="sc-iKUVsf dYnuxz token-amount-input" inputMode="decimal" title="Token Amount"
                      autoComplete="off" autoCorrect="off" type="text" pattern="^[0-9]*[.,]?[0-9]*$"
                      placeholder="0.0" minLength="1" maxLength="79" spellCheck="false" value=""
                      style={{ color: "rgb(255, 255, 255)" }} /><button
                        className="sc-hKFxyN jYLfuR sc-hHSjgo gEfhZZ open-currency-select-button" scale="sm"
                        style={{ color: "rgb(255, 255, 255)" }} onClick={() =>
                        {
                          SelectTokenf(true);
                        }}>
                      <div className="sc-jSFjdj sc-gKAaRy kJmatq eqGhLO">
                        <div id="pair" color="text" className="sc-gtsrHT MlLjM" style={{ color: "rgb(255, 255, 255)" }}>
                          Select a currency</div>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
              {/* <div className="sc-iuhXDa kVpTPX" style="padding: 0px 16px;">
                      <div className="sc-jSFjdj sc-jVSGNQ sc-dHMioH kJmatq kRhOUn jeOpiX">
                        <div color="text" className="sc-gtsrHT sc-hBezlf MlLjM dkXHgw" style={{color: "rgb(255, 255, 255)"}}>
                          Price</div>
                        <div color="text" className="sc-gtsrHT MlLjM"
                          style="justify-content: center; align-items: center; display: flex; color: rgb(255, 255, 255);">
                          0.00260788 BNB per USDT<button className="sc-kSCemg ivcIpC"><svg viewBox="0 0 24 24" width="14px"
                            color="text" xmlns="http://www.w3.org/2000/svg" className="sc-bdnxRM kDWlca">
                            <path
                              d="M12 6V7.79C12 8.24 12.54 8.46 12.85 8.14L15.64 5.35C15.84 5.15 15.84 4.84 15.64 4.64L12.85 1.85C12.54 1.54 12 1.76 12 2.21V4C7.58 4 4 7.58 4 12C4 13.04 4.2 14.04 4.57 14.95C4.84 15.62 5.7 15.8 6.21 15.29C6.48 15.02 6.59 14.61 6.44 14.25C6.15 13.56 6 12.79 6 12C6 8.69 8.69 6 12 6ZM17.79 8.71C17.52 8.98 17.41 9.4 17.56 9.75C17.84 10.45 18 11.21 18 12C18 15.31 15.31 18 12 18V16.21C12 15.76 11.46 15.54 11.15 15.86L8.36 18.65C8.16 18.85 8.16 19.16 8.36 19.36L11.15 22.15C11.46 22.46 12 22.24 12 21.8V20C16.42 20 20 16.42 20 12C20 10.96 19.8 9.96 19.43 9.05C19.16 8.38 18.3 8.2 17.79 8.71Z">
                            </path>
                          </svg></button></div>
                      </div>
                    </div>  */}
            </div>
            <div className="sc-jSFjdj jRqThc"><button className="sc-hKFxyN hizvcO" width="100%" scale="md"
              style={{ background: "rgb(17, 17, 17)", color: "rgb(255, 255, 255)", opacity: "0.65" }}>Connect
              Wallet</button></div>
          </div>
        </div>
      </div>
    );
  }
}



export default AddLiquidity;
