import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Header = ({ props }) => {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const address = useSelector((state) => state.address_web3);
  const user = useSelector((state) => state.user);

  const onConnect = () => {
    if (window.ethereum) {
      window.ethereum.enable().then(() => { });
    }
  };

  const onClick = () => {
    dispatch({
      type: "ACTIVE",
      payload: !state.active,
    });
  };

  return (
    <header className={state.active ? "site-header active" : "site-header"}>
      <div className="menu-and-brand">
        <button
          className={state.active ? "menu-toggle active" : "menu-toggle"}
          onClick={onClick}
        >
          <img
            className="icon-menu-open"
            src="/assets/images/svg-export/icon-menu-open.svg"
            alt="icon menu open"
          />
          <img
            className="icon-menu-close"
            src="/assets/images/svg-export/icon-menu-close.svg"
            alt="icon menu close"
          />
        </button>
        <div className="site-brand-logo">
          <Link to="/btcf/home" title="Safety logo">
            <img
              className="desktop-brand-logo d-md-block"
              src="/assets/images/btf.png"
              alt="btcf logo"
              width={50}
            />
            {/* BTCF */}
          </Link>
        </div>
      </div>
      <div className="site-header-action d-flex justify-content-end">
        {user?.userAddress == "0x0000000000000000000000000000000000000000" ? (
          <button
            className="btn btn-primary btn-connect"
            style={{ marginRight: 10 }}
            type="button"
            onClick={() => {
              props.history.push("/btcf/login");
            }}
          >
            <span className="button-title">Add Referrer</span>
          </button>
        ) : null}
        {address ? (
          <button className="btn btn-primary btn-connect" type="button">
            <span className="button-title">
              {address.slice(0, 6)}...{address.slice(-4)}
            </span>
          </button>
        ) : (
          <button
            className="btn btn-primary btn-connect"
            type="button"
            onClick={onConnect}
          >
            <span className="button-title">Connect</span>
          </button>
        )}
      </div>
    </header>
  );
};

export default Header;
