import React, { Component, createRef } from "react";
// import * as Actions from "./../../actions/index";
// import { connect } from "react-redux";
import Swaptab1 from "components/swap/Swaptab1";

import Swaptab2 from "components/swap/Swaptab2";
import SelectToken from "components/swap/SelectToken";
import SettingSwap from "components/swap/SettingSwap";
import "./style.css";
// import Histogram from './../../components/Histogram';
import Notification from "components/notification/Notification";
import { NotificationManager } from "react-notifications";
// import { createChart } from 'lightweight-charts';

import Loading from "components/loading/Loading2";
// import Loading from "components/loading/Loading2";
import {
  // inFoJoinNow,
  swap,
  getRate,
  checkApproveDm3,
  submitApproveDm3,
  getApproveAllToken,
  submitApproveAlltoken
} from "service";


class Swap extends Component {
  constructor(props) {
    super(props);

    this.d = createRef();

    this.state = {
      SwaptabCollap: true,
      isSelectToken: false,
      isSetting: false,
      Approve: false,
      submitAprove: false,
      NotificationText: '',
      ShowNotification: false,
      submitConnect: false,
      rate: 1,
      amountSwap: 0,
      exchange: true,
      isTokenSelect: 'BTCF',
      approveAllToken: {
        BTCF: false,
        BTCNFT: false,
        BTCEX: false,
        BTCGE: false,
        BTCFX: false,
        USDT: false
      },
      rateAllToken: {
        BTCF: 0,
        BTCNFT: 0,
        BTCEX: 0,
        BTCGE: 0,
        BTCFX: 0,
        USDT: 0
      },
      numAllToken: {
        BTCF: 1,
        BTCNFT: 2,
        BTCEX: 5,
        BTCGE: 4,
        BTCFX: 3,
        USDT: 0
      }

    }
  }

  _submitApprove = () => {
    console.log("ok", this.state.isTokenSelect)
    this.setState(state => {
      return {
        submitAprove: true
      }
    }, async () => {
      let nameSub = '';
      if (this.state.exchange) {
        nameSub = 'USDT'
      } else {
        nameSub = this.state.isTokenSelect
      }
      let SubmitAprove = await submitApproveAlltoken(nameSub);
      console.log("SubmitAprove", SubmitAprove);
      // let SubmitAprove = true;
      // return
      if (SubmitAprove) {
        NotificationManager.success("Successfully", "Success", 10000);
        // this.setNotificationText("Successfully");
        this.setState(state => {
          return {
            Approve: true,
            submitAprove: false
          }
        })
      } else {
        NotificationManager.success("fail", "Error", 10000);
        // this.setNotificationText("fail");
        this.setState(state => {
          return {
            Approve: false,
            submitAprove: false
          }
        })
      }
    });
  }
  SubmitConnect = () => {
    this.setState(state => {
      return {
        submitConnect: true
      }
    }, async () => {
      let ins, out;
      let amountSW = this.state.amountSwap;
      if (this.state.exchange) {
        ins = 0;
        out = this.state.numAllToken[this.state.isTokenSelect]
      } else {
        ins = this.state.numAllToken[this.state.isTokenSelect]
        amountSW = this.state.amountSwap * this.state.rateAllToken[this.state.isTokenSelect];
        out = 0
      }
      let submitConnect = await swap(amountSW, ins, out);
      if (submitConnect) {
        // this.setNotificationText("Successfully");
        NotificationManager.success("Successfully", "Success", 10000);
        this.setState(state => {
          return {
            Approve: true,
            submitConnect: false
          }
        })
      } else {
        // this.setNotificationText("fail");
        NotificationManager.success("fail", "Error", 10000);
        this.setState(state => {
          return {
            Approve: false,
            submitConnect: false
          }
        })
      }
    })

  }
  async componentDidMount() {
    this.getApprove()
  }

  getApprove = async () => {
    let data = await getApproveAllToken();
    let dataRate = await getRate();

    this.setState(state => {
      return {
        approveAllToken: data,
        rateAllToken: dataRate
      }
    })
    setInterval(async () => {
      let data = await getApproveAllToken();
      let dataRate = await getRate();

      this.setState(state => {
        return {
          approveAllToken: data,
          rateAllToken: dataRate
        }
      })
    }, 3000);
  }

  SelectTokenf = (type) => {
    this.setState(state => {
      return {
        isSelectToken: type
      }
    })
  }

  Setingshow = (type) => {
    this.setState(state => {
      return {
        isSetting: type
      }
    })
  }
  ChangeTab = (type) => {
    this.setState(state => {
      return {
        SwaptabCollap: type
      }
    })
  }

  handleChange = (event) => {
    // console.log(event);
    // return
    this.setState(state => {
      return {
        amountSwap: event
      }
    });
  }
  slecsga = (anme) => {
    // console.log(anme);
    this.setState(state => {
      return {
        isTokenSelect: anme
      }
    });
    this.SelectTokenf(false)
  }
  changeExchange = () => {
    this.setState(state => {
      return {
        exchange: !state.exchange
      }
    });
  }

  render() {
    let { SwaptabCollap, isSelectToken, isSetting, Approve, ShowNotification, NotificationText, submitAprove, submitConnect, rate, isTokenSelect, exchange, approveAllToken, rateAllToken } = this.state;
    let active1 = SwaptabCollap ? "kUxtnz" : "";
    let active2 = !SwaptabCollap ? "kUxtnz" : "";
    let wscren = 0, isScreen = window.screen.width;

    if (isScreen < 450) {
      wscren = isScreen - 30
    } else if (isScreen > 1500) {
      wscren = 1448
    } else {
      wscren = isScreen - 100
    }

    return (
      <div className="swap css-i7xsun">

        {ShowNotification && <Notification NotificationText={NotificationText} />}
        {isSetting && <SettingSwap />}
        {isSelectToken && <SelectToken SelectTokenf={this.SelectTokenf} selectTokens={(name) => { this.slecsga(name) }} />}
        <main className="site-main-content container-fluid">
          <div className="row" style={{ margin: "0px" }}>
            <div className="sc-iQQLPo SwOQV swap-block " style={{ background: "unset" }}>

              {SwaptabCollap && <Swaptab1 rateAllToken={rateAllToken} approveAllToken={approveAllToken} changeExchange={this.changeExchange} exchange={exchange} rate={rate} isTokenSelect={isTokenSelect} SelectTokenf={this.SelectTokenf} handleChange={this.handleChange} Approve={Approve} Loading={Loading} submitAprove={submitAprove} _submitApprove={this._submitApprove} _SubmitConnect={this.SubmitConnect} submitConnect={submitConnect} />}
              {!SwaptabCollap && <Swaptab2 />}

            </div>
          </div>

        </main>
      </div>
    );
  }
}
export default Swap;
