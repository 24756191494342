import React, { Component, createRef } from "react";
import TokenItem from "./TokenItem";

import "./style.css";

class SettingSwap extends Component
{


  constructor(props)
  {
    super(props);
    this.sa = createRef();
    this.sa1 = createRef();
    this.state = {
      val: "0.50"
    }
  }

  setActive = (type, e) =>
  {
    if (type === 1) {
      let sa = document.getElementsByClassName("s_a1");
      let id = e.target.getAttribute("data-id");
      // let sa = this.sa;

      for (let i = 0; i < sa.length; i++) {
        const element = sa[i];

        if (element.getAttribute("data-id") === id) {
          element.classList.add("active");
        }
        else {
          element.classList.remove("active");
        }

      }
    }
    if (type === 2) {
      let sa = document.getElementsByClassName("s_a2");
      let id = e.target.getAttribute("data-id");
      // let sa = this.sa;

      for (let i = 0; i < sa.length; i++) {
        const element = sa[i];

        if (element.getAttribute("data-id") === id) {
          element.classList.add("active");
        }
        else {
          element.classList.remove("active");
        }

      }
    }
  }

  render()
  {
    let { Setingshow } = this.props;
    // NotificationText = "Successfully"
    return (
      <div className="select_token">

        <div className="content">
          <div className="title">
            <h4>Settings</h4>
            <button className="" onClick={() => { Setingshow(false) }}><img src="images/delete.png" alt="" /></button>
          </div>
          <div className="m setting">
            <div className="block">
              <div className="t">GLOBAL</div>
              <div className="d">Default Transaction Speed (GWEI) <span title="Adjusts the gas price (transaction fee) for your transaction. Higher GWEI = higher speed = higher fees" className="tooltips"><i class="fa fa-question-circle-o" aria-hidden="true"></i></span></div>
              <div className="btn_ac">
                <button className="s_a s_a1 active" data-id="1" ref={this.sa} onClick={(event) =>
                {
                  this.setActive(1, event);
                }}>Standard (5)</button>
                <button className="s_a s_a1" data-id="2" ref={this.sa} onClick={(event) =>
                {
                  this.setActive(1, event);
                }}>Fast (6)</button>
                <button className="s_a s_a1" data-id="3" ref={this.sa} onClick={(event) =>
                {
                  this.setActive(1, event);
                }}>Instant (7)</button>
              </div>
            </div>
            <div className="block">
              <div className="t">SWAPS & LIQUIDITY</div>
              <div className="d">Slippage Tolerance

                <span className="tooltips" title="Setting a high slippage tolerance can help transactions succeed, but you may not get such a good price. Use with caution.">
                  <i class="fa fa-question-circle-o" aria-hidden="true"></i>
                </span>
              </div>
              <div className="btn_ac">
                <button className="s_a s_a2 active" data-id="1" ref={this.sa1} onClick={(event) =>
                {
                  this.setActive(2, event);
                }}>0.1%</button>
                <button className="s_a s_a2" data-id="2" ref={this.sa1} onClick={(event) =>
                {
                  this.setActive(2, event);
                }}>0.5%</button>
                <button className="s_a s_a2" data-id="3" ref={this.sa1} onClick={(event) =>
                {
                  this.setActive(2, event);
                }}>0.5%</button>
                <div className="inp_val">
                  <input type="text" value={this.state.val} id="val" name="val" />
                </div>
              </div>
            </div>
            <div className="block block_last">

              <div className="d">Slippage Tolerance
                <span className="tooltips" title="Your transaction will revert if it is left confirming for longer than this time.">
                  <i class="fa fa-question-circle-o" aria-hidden="true"></i>
                </span>
              </div>
              <div className="inp_val">
                <input type="text" value={this.state.val} id="val" name="val" />
              </div>

            </div>
          </div>

        </div>
      </div>
    );
  }
}



export default SettingSwap;
