import React, { Component } from "react";


import "./style.css";
class TokenItem extends Component
{


  constructor(props)
  {
    super(props);


  }


  render()
  {
    let { name, img, des, selectTokens } = this.props;
    // NotificationText = "Successfully"
    return (
      <div className="token_item" style={{cursor: 'pointer'}} onClick={() => { selectTokens(name) }}>
        <div className="middle">
          <div className="token_name">{name}</div>

        </div>
        <div className="token_ac"></div>
      </div>
    );
  }
}



export default TokenItem;
