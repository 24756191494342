import React, { Component } from "react";


import "./style.css";
class YourLiquidity extends Component
{


  constructor(props)
  {
    super(props);


  }


  render()
  {
    let { Setingshow, ChangeTab } = this.props;
    // NotificationText = "Successfully"
    return (
      <div id="cus_id" className="sc-iemWCZ hvoWDu sc-eSoXWK WzEhh" style={{ background: "unset", padding: "0px" }}>
        <div className="sc-jSFjdj sc-dIvrsQ kJmatq hdzOgI">
          <div className="sc-jSFjdj sc-gKAaRy sc-jNjAJB kJmatq jcNvwq jvbdxC swap-page__header">
            <div className="sc-jSFjdj sc-gKAaRy cpromH togOu"><a className="sc-eCApnc fAYopv" href="">
              <div
                style={{ background: "rgb(255, 255, 255)", borderRadius: "50%", height: "40px", width: "40px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <img src="/images/image-ether.png" width="30" alt="" /></div>
            </a>
              <div className="sc-jSFjdj sc-gKAaRy kJmatq iIdAKa">
                <h2 color="text" className="sc-gtsrHT sc-iJCRrE iGtzpP jXZUNo"
                  style={{ color: "rgb(255, 255, 255)", fontWeight: "500", fontSize: "28px", fontFamily: "sans-serif", margin: "0px" }}>
                  Your Liquidity</h2>
                <div className="sc-jSFjdj sc-gKAaRy kJmatq togOu"
                  style={{ color: "rgb(255, 255, 255)", fontWeight: "500", fontSize: "16px", fontFamily: "sans-serif" }}>
                  <div color="#fff" fontSize="14px" className="sc-gtsrHT iNjjsC">Remove liquidity to receive tokens back</div>
                </div>
              </div>
            </div>
            <div className="sc-jSFjdj sc-gKAaRy kJmatq togOu"><span className="sc-cTJkRt lmGFyU">
              <div className="sc-jSFjdj sc-gKAaRy kJmatq jcNvwq"><button className="sc-hKFxyN iLUutT sc-eCApnc fAYovO"
                scale="sm" id="open-settings-dialog-button" onClick={() => { Setingshow(true) }}>  <img src="images/settings.png" alt="" /></button></div><span className="sc-jNnpgg cFAkok"></span>
            </span></div>
          </div>
          <div className="sc-hHEiqL sc-jxFFCz fmYIqW hTpcqx"
            style={{}}>
            <div color="white" className="sc-gtsrHT bwlMme">Connect to a wallet to view your liquidity.</div>
          </div>
          <div className="sc-kfYoZR jeCzKb"
            style={{ textAlign: "center" }}>
            <button className="sc-hKFxyN hizvcO" id="join-pool-button" width="100%" variant="primary" scale="md"
              onClick={() => { ChangeTab(false) }}>
              <svg viewBox="0 0 24 24" color="white" width="20px" xmlns="http://www.w3.org/2000/svg"
                className="sc-bdnxRM dPYiZw">
                <path
                  d="M18 13H13V18C13 18.55 12.55 19 12 19C11.45 19 11 18.55 11 18V13H6C5.45 13 5 12.55 5 12C5 11.45 5.45 11 6 11H11V6C11 5.45 11.45 5 12 5C12.55 5 13 5.45 13 6V11H18C18.55 11 19 11.45 19 12C19 12.55 18.55 13 18 13Z">
                </path>
              </svg>Add Liquidity

            </button>
          </div>
        </div>
      </div>
    );
  }
}



export default YourLiquidity;
