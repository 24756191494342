import React, { Component } from "react";

import YourLiquidity from "./YourLiquidity";
import AddLiquidity from "./AddLiquidity";
import "./style.css";
class Swaptab2 extends Component
{


  constructor(props)
  {
    super(props);
    this.state = {
      SwaptabCollap: true,

    }

  }

  ChangeTab = (type) =>
  {
    this.setState(state =>
    {
      return {
        SwaptabCollap: type
      }
    })
  }
  render()
  {
    let { Setingshow, SelectTokenf } = this.props;
    let { SwaptabCollap } = this.state;
    // NotificationText = "Successfully"
    return (

      <>
        {SwaptabCollap && <YourLiquidity Setingshow={Setingshow} ChangeTab={this.ChangeTab} />}
        {!SwaptabCollap && <AddLiquidity Setingshow={Setingshow} ChangeTab={this.ChangeTab} SelectTokenf={SelectTokenf} />}
      </>

    );
  }
}



export default Swaptab2;
