import { contract_address } from "config";
import { contract_abi } from "config";
import React, { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useSelector } from "react-redux";
import Web3 from "web3";
import {
  getInfoStaking,
  staking,
  getInfoUser,
  addTree,
  checkApproveStaking,
  submitApproveStaking,
  unStaking,
  withdraw
} from "service";

const LoadingButton = ({ className }) => {
  return (
    <button className={className}>
      <div className="spinner-border spinner-border-sm" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </button>
  );
};

const Staking = (props) => {
  const state = useSelector((state) => state);
  const [loading, setLoading] = useState(false);
  const [profit, setProfit] = useState(0);
  const [approve, setApprove] = useState(false);
  const [packageStaking, setPackageStaking] = useState({});
  const [BNB, setBNB] = useState(0);
  const [SFT, setSFT] = useState(0);
  const [balance, setBalance] = useState(0);
  const [price, setPrice] = useState({
    symbolPrice: 0,
    priceSFT: 0,
    priceBNB: 0,
    bnb: 0,
  });

  useEffect(() => {
    setInterval(() => {
      getData();
    }, 1000);
  }, [1000]);

  useEffect(() => {
    // getProfit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.user]);

  const getData = async () => {
    const infoStaking = await getInfoStaking();
    const checkApp = await checkApproveStaking();
    setApprove(checkApp.stt);
    setBalance(checkApp.balance);
    let obj = {};
    // switch (infoStaking.types) {
    //   case '0':
    //     obj.pt = 5;
    //     break;
    //   case '1':
    //     obj.pt = 6;
    //     break;
    //   case '2':
    //     obj.pt = 7;
    //     break;
    //   case '3':
    //     obj.pt = 8;
    //     break;
    //   case '4':
    //     obj.pt = 10;
    //     break;
    //   case '5':
    //     obj.pt = 12;
    //     break;
    //   case '6':
    //     obj.pt = 15;
    //     break;
    //   case '7':
    //     obj.pt = 18;
    //     break;
    //   case '8':
    //     obj.pt = 21;
    //     break;
    //   default:
    //     obj.pt = 0;
    //     break;
    // }

    obj.amount = Number(infoStaking.amount) / 1e18;
    obj.received = Number(infoStaking.received) / 1e18;
    obj.timestamp = Number(infoStaking.timestamp);
    obj.pt = infoStaking.pt;
    let times = Math.floor(Date.now() / 1000) - obj.timestamp;
    obj.profit = (obj.amount * times * infoStaking.pt / 259200000) - obj.received;
    if (obj.amount == 0) {
      infoStaking.pt = 0
    }
    setPackageStaking(obj)
  };


  // ô điền số lượng
  // nút đầu tư
  // tổng đầu tư
  // % / tháng
  // số dư
  // tổng lợi nhuận
  // đã rút
  // rút
  const onConfirmSFT = async () => {
    return
    // console.log('SFT is ', SFT);
    if (SFT < 100) {
      NotificationManager.error("Min 100", "Error", 10000);
    }
    setLoading(true);
    let data = await staking(SFT);
    console.log(data);
    if (!data) {
      NotificationManager.error("Error", "Error", 10000);
    } else {
      NotificationManager.success("Investment Success", "Error", 10000);
    }
    setLoading(false);
    getData();
  }

  const _approve = async () => {
    return
    setLoading(true);
    let data = await submitApproveStaking();
    console.log(data);
    if (!data) {

    }

    setLoading(false);
    getData();
  }

  const onClaim = async () => {
    setLoading(true);
    let data = await withdraw();
    console.log(data);
    if (!data) {
      NotificationManager.error("Error", "Error", 10000);
    } else {
      NotificationManager.success("Withdraw Success", "Error", 10000);
    }

    setLoading(false);
    getData();
  }

  const _unStaking = async () => {
    setLoading(true);
    let data = await unStaking();
    console.log(data);
    if (!data) {
      NotificationManager.error("Error", "Error", 10000);
    } else {
      NotificationManager.success("Success", "Error", 10000);
    }

    setLoading(false);
    getData();
  }


  return (

    <div className="site-main-content__buy-sft">
      <div className="container">
        <div className="manage-your-token-title">
          <h3 className="main-title">Mining</h3>
        </div>
        <div className="row">
          <div className="col col-12 col-sm-6">
            <div className="block-item item-swap-rates">
              <div className="block-item__wrap">
                <div className="block-item__content">
                  <div className="buy-sft">
                    <div className="row">
                      <p>Balance BTCF: {balance.toFixed(2)}</p>
                      <div className="buy-sft-field col col-12">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="0"
                          onChange={(e) => {
                            setSFT(Number(e.target.value));
                          }}
                        />
                        <span className="unit">BTCF</span>
                      </div>
                    </div>
                    {
                      approve ? <div className="buy-sft-action text-center">
                        {loading ? (
                          <LoadingButton className="btn btn-primary" />
                        ) : (
                          <button
                            className="btn btn-primary"
                            onClick={onConfirmSFT}
                          >
                            Investment
                          </button>
                        )}
                      </div>
                        :
                        < div className="buy-sft-action text-center">
                          {loading ? (
                            <LoadingButton className="btn btn-primary" />
                          ) : (
                            <button
                              className="btn btn-primary"
                              onClick={_approve}
                            >
                              Approve
                            </button>
                          )}
                        </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col col-12 col-sm-6">
            <div className="block-item item-swap-rates">
              <div className="block-item__wrap">
                <div className="block-item__content">
                  <div className="buy-sft">
                    <div className="row">
                      <p>Created: {(new Date(packageStaking?.timestamp * 1000))?.toLocaleString()}</p>
                      <div>Note: </div>
                      <p>* Please long Claim before using this function!</p>
                    </div>
                    <div className="buy-sft-action text-center">
                      {loading ? (
                        <LoadingButton className="btn btn-primary" />
                      ) : (
                        <button
                          className="btn btn-primary"
                          onClick={() => {
                            _unStaking();
                          }}
                        >
                          Un Investment
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        {/* <div className="manage-your-token-title">
          <p className="sub-title">PERSONAL TITLE</p>
          <h3 className="main-title">Check and manage your USDT</h3>
        </div> */}
        <div className="row">
          <div className="col col-12 col-md-3">
            <div className="block-item item-manage-your-token">
              <div className="block-item__wrap text-center">
                <h3 className="block-item__title">% you can claim</h3>
                <div className="block-item__content">
                  <h3>
                    <span className="amount">
                      {
                        Number(packageStaking?.pt || 0).toFixed(0)}
                    </span>
                    %
                  </h3>
                </div>
                <div className="block-item__footer">Per month</div>
              </div>
            </div>
          </div>
          <div className="col col-12 col-md-3">
            <div className="block-item item-manage-your-token">
              <div className="block-item__wrap text-center">
                <h3 className="block-item__title">BTCF bought</h3>
                <div className="block-item__content">
                  <h3>
                    <span className="amount">
                      {Number(packageStaking?.amount || 0).toFixed(2)}
                    </span>
                  </h3>
                </div>
                <div className="block-item__footer">BTCF</div>
              </div>
            </div>
          </div>
          <div className="col col-12 col-md-3">
            <div className="block-item item-manage-your-token">
              <div className="block-item__wrap  text-center">
                <h3 className="block-item__title">BTCF claimed</h3>
                <div className="block-item__content">
                  <h3>
                    <span className="amount">
                      {Number(packageStaking?.received || 0).toFixed(
                        6
                      )}
                    </span>
                  </h3>
                </div>
                <div className="block-item__footer">BTCF</div>
              </div>
            </div>
          </div>
          <div className="col col-12 col-md-3">
            <div className="block-item item-manage-your-token">
              <div className="block-item__wrap text-center">
                <h3 className="block-item__title">BTCF ready for claim</h3>
                <div className="block-item__content">
                  <h3>
                    <span className="amount">
                      {(packageStaking.profit || 0).toFixed(
                        Number(profit) > 100
                          ? 2
                          : Number(profit) > 10
                            ? 4
                            : Number(profit) > 1
                              ? 6
                              : 8
                      )}
                    </span>
                  </h3>
                </div>
                <div className="block-item__footer">
                  {loading ? (
                    <LoadingButton className="btn btn-primary" />
                  ) : (
                    <button
                      type="submit"
                      className="btn btn-primary btn-claim"
                      onClick={onClaim}
                    >
                      CLAIM
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default Staking;
