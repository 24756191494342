import React, { Component } from "react";


import "./style.css";
class Notification extends Component
{


  constructor(props)
  {
    super(props);


  }


  render()
  {
    let { showNotification, NotificationText } = this.props;
    let show = '';
    if (showNotification === true) {
      show = 'show_noti';
    }
    // NotificationText = "Successfully"
    return (
      <div id="Notification" className={"Notification show_noti " + show}>
        <p>{NotificationText}</p>
      </div>
    );
  }
}



export default Notification;
