import React from "react";
import { NotificationContainer } from "react-notifications";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Pages from "views/pages";
import "./style.scss";

const App = () => {
  return (
    <BrowserRouter>
      <NotificationContainer />
      <Switch>
        <Route path={"/btcf"} component={Pages} />
        <Redirect from={"*"} to={"/btcf"} />
      </Switch>
    </BrowserRouter>
  );
};

export default App;
